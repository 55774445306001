@import '~@reasoncorp/kyber-js/dist/scss/themes/misuite-v2/theme-misuite-v2';

span.required:before {
  content: "*";
  color: red;
  margin-right: 2px;
  font-size: 1rem;
}

.row:focus, .col:focus, [role="tabpanel"]:focus {
  outline: none;
}

.tab-nav li {
  list-style: none;
  display: inline-block;

  &:hover {
    background: rgba(0, 0, 0, 0.15);
  }
}

// Allow copying of values with single click
.no-select {
  user-select: none;
}

.selectable {
  user-select: all;
}

// Enable landscape printing
@media print {
  @page {
    margin: 0.4cm;
    size: A3 landscape;
  }

  .tab-nav, .audit-form, .Footer, button {
    display: none !important;
  }

  .card-header, th {
    color: black;
  }

  .card {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12) !important;
  }
}

// Paginator
.Paginator {
  padding-left: 1rem !important;
}

// Clean screen scroll card
.clean-nav-col, .clean-nav-col .card {
  max-height: 38rem;
}

.clean-card-scroll {
  max-height: 37.75rem;
  overflow-y: auto;
}